// @flow

import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

import Logo from "../assets/logo.svg";
import useSiteMetadata from "../hooks/use-sitemetadata";

const LandingStyled = styled("main")`
  font-family: "Open Sans", sans-serif;
  min-height: 100vh;
  background: #fddb5d;

  strong {
    font-size: 28px;
    font-weight: bold;
    border-bottom: 3px solid black;
    padding-bottom: 20px;
  }

  .fade-in {
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
  }
  @keyframes fadeIn {
    0% {
      padding-bottom: 5px;
      opacity: 0;
    }
    50% {
      padding-bottom: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const ContentStyled = styled("div")`
  max-width: 900px;
  min-height: 600px;
`;

const LogoStyled = styled(Logo)`
  width: 320px;
`;

const QuoteStyled = styled("p")`
  font-size: 26px;
  font-weight: bold;
  line-height: 45px;
`;

const AuthorStyled = styled("div")`
  font-size: 22px;
`;

const StartLinkStyled = styled(Link)`
  background: black;
  border-radius: 25px;
  font-size: 22px;
  font-weight: bold;
  color: white;
  &:hover {
    color: white;
  }
`;

const quotes = [
  {
    text:
      "Moja babcia Brońcia śpiewała pieśń „Na Podolu”, która jest bardzo stara.",
    author: "Maria Sikora – Górzanki"
  },
  {
    text: "Chodzimy do zespołu, bo to nas pociąga.",
    author: "Brzękowianie"
  },
  {
    text:
      "Tworzymy również własne kompozycje bazując na materiale regionalnym. Często są pisane w gwarze, w naszej gwarze.",
    author: "Kapela Gródków"
  },
  {
    text:
      "Mój świętej pamięci tata też grał i od niego mam niektóre pieśni, mam też zagłębiowskie śpiewniki, na przykład Adolfa Dygacza.",
    author: "Leopold Surma – Nasz Gródków"
  },
  {
    text:
      "U nas panie chodziły w strojach… pamiętam hafty i koronki robione na szydełku.",
    author: "Strzyżowiczanie"
  },
  {
    text:
      "Jako dzieci śpiewaliśmy przy pierzu, były wyskubki, napiekło się placka drożdżowego i potem była zabawa.",
    author: "Dąbie"
  }
];

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

function Home() {
  const { title, description } = useSiteMetadata();
  const [quote, setQuote] = useState(quotes[getRandomInt(0, 5)]);

  const helmet = (
    <Helmet>
      <html lang="pl" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setQuote(quotes[getRandomInt(0, 5)]);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {helmet}
      <LandingStyled className="d-flex align-items-center">
        <ContentStyled className="py-3 d-flex flex-column justify-content-end container">
          <div className="d-flex flex-column flex-grow-1">
            <h1 className="p-0 m-0 mb-2">
              <LogoStyled alt="Wyśpiewana Tradycja" />
            </h1>
            <strong>zespoły śpiewacze Zagłębia Dąbrowskiego</strong>
          </div>
          <blockquote className="mt-5" key={quote.text}>
            <QuoteStyled className="fade-in">{quote.text}</QuoteStyled>
            <AuthorStyled className="fade-in">({quote.author})</AuthorStyled>
          </blockquote>
          <div className="d-flex justify-content-end mt-5">
            <StartLinkStyled className="py-3 px-3" to="/zespoly/">
              Przejdź do strony głównej →
            </StartLinkStyled>
          </div>
        </ContentStyled>
      </LandingStyled>
    </>
  );
}

export default Home;
